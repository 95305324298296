/*
 * @Description: 主题配置文件
 * @Author: Mars
 * @Date: 2023-12-04 09:30:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-10-31 10:56:13
 */
import { ThemeConfig } from 'antd';

export const commonTheme: ThemeConfig = {
  token: {
    colorTextPlaceholder: 'rgba(69, 69, 69,0.65)',
    colorPrimary: '#00B3EE',
    fontFamily: "-apple-system,'WenQuanYi Micro Hei','Micro Hei','Noto Sans CJK','Source Han Sans',Montserrat,Roboto,Lato,sans-serif",
  },
  components: {
    Button: {
      colorTextLightSolid: 'rgb(69, 69, 69)',
    },
    Radio: {
      colorTextLightSolid: 'rgb(69, 69, 69)',
      borderRadiusLG: 40,
      borderRadius: 32,
      borderRadiusSM: 24,
      // colorBorder: '#FABF10',
      buttonColor: '#00B3EE',
      buttonBg: '#fffde6',
    },
  },
};
